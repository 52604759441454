import React, { useState } from 'react'
import styled from 'styled-components'
import Footer from '../components/Footer'
import { Button, Pulse, StyledButton, TextInput } from '@monbanquet/crumble'
import { httpFetch } from '../util/httpFetch'
import { navigate } from '../components/Link'
import Navbar from '../components/Navbar'
import Helmet from 'react-helmet'
import { layout } from '../theme'

const passwordForgot = async (emailAccount: string): Promise<void> => {
  await httpFetch(
    `${process.env.GATSBY_VENUS_BACK_URL}/api/public/auth/forget-password?customerEmail=${emailAccount}`,
    { method: 'POST', noAuth: true },
  )
}

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [sending, setSending] = useState(false)

  return (
    <StyledForgotPasswordPage>
      <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
      <Navbar />
      <div className="login-box">
        <h2>Réinitialisez votre mot de passe</h2>

        {!success && (
          <>
            <h4>
              Indiquez l&apos;adresse email associée à votre compte.
              <br />
              Nous vous y enverrons les instructions pour réinitialiser votre
              mot de passe
            </h4>

            <form
              onSubmit={async e => {
                e.preventDefault()
                try {
                  setSending(true)
                  await passwordForgot(email)
                  setSuccess(true)
                } catch (err) {
                  setError(err.userMessage)
                } finally {
                  setSending(false)
                }
              }}
            >
              <TextInput
                className="email-input"
                label="Adresse email"
                type="email"
                autoComplete="email"
                secondary
                placeholder="mon@email.com"
                required
                autoFocus
                value={email}
                onChange={emailInput => setEmail(emailInput)}
              />
              <div className="btns">
                <Button onClick={() => navigate(-1)} className="cancel">
                  Annuler
                </Button>
                <Button type="submit">
                  {sending ? <Pulse /> : `Réinitialiser`}
                </Button>
              </div>
            </form>
          </>
        )}
        {success && (
          <div className="success">
            <p className="main-info">
              Vous devriez recevoir un e-mail dans les prochaines minutes avec
              un lien pour réinitialiser votre mot de passe.
            </p>
            <p>
              Si vous n&apos;avez pas reçu cet e-mail d&apos;ici quelques
              minutes ?
            </p>
            <ol>
              <li>1. Consultez votre dossier spams ou courrier indésirable</li>
              <li>
                2. L&apos;adresse e-mail que vous avez saisie est peut-être
                incorrecte
              </li>
              <li>
                3. L&apos;adresse e-mail saisie n&apos;est peut-être pas
                enregistrée chez nous.
              </li>
            </ol>
          </div>
        )}
        {error && <p className="error">{error}</p>}
      </div>
      <Footer />
    </StyledForgotPasswordPage>
  )
}

const StyledForgotPasswordPage = styled.div`
  .login-box {
    margin-top: ${layout.navbarHeight.normal}px;
    h2,
    h4 {
      max-width: 500px;
      width: 100%;
    }

    h4 {
      font-weight: normal;
      margin-top: 10px;
      font-size: 16px;
      line-height: 22px;
    }

    .success {
      font-size: 13px;
      max-width: 500px;
      line-height: 22px;

      .main-info {
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 16px;
      }

      a {
        color: #fc4c4c;
      }
    }

    form {
      width: 100%;
      padding-top: 30px;
      max-width: 500px;
      .error {
        margin-top: 20px;
        color: #fc4c4c;
        text-align: center;
      }
      .email-input {
        margin-bottom: 20px;
      }
      .btns {
        display: flex;
      }
      ${StyledButton} {
        border-radius: 4px;
      }
      .cancel {
        background: #aaa;
        margin-right: auto;
      }
      .btn-text {
        margin: 10px 0;
        appearance: none;
        border: none;
        background: none;
        font-size: 12px;
        cursor: pointer;
        color: #999;
        transition: all 50ms ease-in-out;
        &:hover {
          text-decoration: underline;
          color: #bc8e15;
        }
      }
    }
  }
`

export default ForgotPasswordPage
